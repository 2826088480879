import routeParams from '@RouteParams';

const routeParamsJSON = JSON.parse(JSON.stringify(routeParams));

const domains = routeParamsJSON ? routeParamsJSON.domains : {};
const themes = routeParamsJSON ? routeParamsJSON.themes : {};
const attributes = routeParamsJSON ? routeParamsJSON.attributes : {};
const countries = routeParamsJSON ? routeParamsJSON.countries : [];
const accoTypes = routeParamsJSON ? routeParamsJSON.accoTypes : {};
const campaigns = routeParamsJSON ? routeParamsJSON.campaigns : {};
const prikdatum = routeParamsJSON ? routeParamsJSON.prikdatum : {};
const chains = routeParamsJSON ? routeParamsJSON.chains : [];
const tourOperators = routeParamsJSON ? routeParamsJSON.tourOperators : [];

const baseRoutes = require('@AppRoutes/index').routes;

export { baseRoutes, countries, themes, attributes, domains, accoTypes, campaigns, prikdatum, chains, tourOperators };
