/**
 * Check if the path starts with '//' and append 'https'.
 * Mainly used for links being returned from Contentful.
 * @param imageURL
 * @returns string
 */
const prependProtocol = (imageURL: string): string => {
	return imageURL.replace(/^\/\//, 'https://');
};

export default prependProtocol;
